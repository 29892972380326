import React from 'react';
import LogoDebtAdvisor from '../images/logos/logo-debt-advisor-with-icon.png';

export default function creditCounselingOffers() {
    return {
        offers: [{
            logo: LogoDebtAdvisor,
            company: 'Debt Advisor',
            link: 'https://www.ac934intrk.com/3J67C/37GQ4B/?sub3=60456',
            rating: 5,
            details: [{
                header: 'Better Business Bureau',
                content: 'Not rated',
            }, {
                header: 'Years in Business',
                content: '6+', 
            }, {
                header: 'Free Consultation?',
                content: 'Yes',
            }],
            mainValueProp: 'Reduce your debt with Debt Advisor',
            benefits: [
                {
                    item: 'No-cost consultations',
                },
                {
                    item: 'Cut your credit card payments by up to 50%',
                },
                {
                    item: 'Receive funds as soon as the next business day!',
                },
                {
                    item: 'Click here for official site, terms, and details',
                    link: true,
                },
            ],
            review: (
                <>
                    <p>With over 1 million debt relief requests yearly, Debt Advisor is one of several owned and operated brands connecting consumers seeking debt relief with financial service providers nationwide. Financial problems arise and we help consumers f ind a solution for their debt relief needs.</p>
                </>
            ),
        }]
    }
}