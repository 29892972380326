import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Container, List, Grid, Header, Accordion
} from 'semantic-ui-react';
import * as OptionsSection from "./OptionsSection";

class OptionsTemplate extends React.Component {
    
    
    state = { activeIndex: 0 }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }


    render() {
        const { activeIndex } = this.state
        const { items } = this.props;

        if (!items || items.length) {
            console.log("items missing");
            return null;
        }

        
        return (
            <div className="options">
                <Container>
                    {items.offers.map((item, idx) => (
                        <OptionsSection.Wrapper
                            logo={item.logo}
                            company={item.company}
                            link={item.link}
                            rating={item.rating}
                            key={idx.toString()}
                        >
                            <OptionsSection.Heading>
                                {item.mainValueProp}
                            </OptionsSection.Heading>
                            <OptionsSection.Body>
                                <List bulleted>
                                    {item.benefits.map((benefit, benefitIdx) => (
                                        <List.Item key={benefitIdx.toString()}>
                                            {benefit.link ? (
                                                <strong>
                                                    <a 
                                                        href={item.link}
                                                        target='_blank'
                                                        rel="noopener noreferrer sponsored"
                                                    >
                                                        {benefit.item}
                                                    </a>
                                                </strong>
                                            ): benefit.item}
                                        </List.Item>    
                                    ))}
                                </List>
                            </OptionsSection.Body>    
                            <OptionsSection.Details>
                                <div className="options--details">
                                    {item.details.map((detail, detailIdx) => (
                                        <div className="options--details-container" key={detailIdx.toString()}>
                                            <Header size="small" className="options--details__header">
                                                {detail.header}
                                            </Header>
                                            <div className="options--details__content">
                                                {detail.additional ? (
                                                    <Accordion>
                                                        <Accordion.Title
                                                            active={activeIndex === idx.toString()}
                                                            index={idx.toString()}
                                                            onClick={this.handleClick}
                                                        >
                                                        {detail.content}
                                                        </Accordion.Title>
                                                        <Accordion.Content active={activeIndex === idx.toString()}>
                                                            {detail.additional} 
                                                        </Accordion.Content>
                                                    </Accordion>
                                                ): detail.content }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </OptionsSection.Details>  
                            <OptionsSection.Review>
                                {item.review}
                            </OptionsSection.Review>
                            <OptionsSection.Additional>
                                <List bulleted>
                                    {item.benefits.map((benefit, benefitIdx) => (
                                        <List.Item key={benefitIdx.toString()}>
                                            {benefit.link ? (
                                                <strong>
                                                    <a 
                                                        href={item.link}
                                                        target='_blank'
                                                        rel="noopener noreferrer sponsored"
                                                    >
                                                        {benefit.item}
                                                    </a>
                                                </strong>
                                            ): benefit.item}
                                        </List.Item>    
                                    ))}
                                </List>
                            </OptionsSection.Additional>
                        </OptionsSection.Wrapper>
                    ))}
                </Container>
            </div>
        );
    }
}

OptionsTemplate.propTypes = {
    items: PropTypes.object.isRequired,
};

OptionsTemplate.defaultProps = {
    showDisclosures: true,
};

export default OptionsTemplate;
