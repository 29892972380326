import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
    margin-bottom: 0.6em;
`

const StarRating = ({
    rating,
    size,
}) => {
    const starRating = (Math.round(rating * 2) / 2);

    const stars = Array(5)
        .fill(<Icon name="star outline" color="yellow" size={size} />)
        .fill(<Icon name="star" color="yellow" size={size} />, 0, Math.floor(starRating));

    if (starRating % 1) {
        stars[Math.floor(starRating)] = (
            <Icon.Group size={size} style={{ marginRight: '0.25rem' }}>
                <Icon color="yellow" name="star outline" style={{ marginRight: '0' }} />
                <Icon color="yellow" name="star half" />
            </Icon.Group>
        );
    }

    return (
        <StyledDiv>
            {stars.map((component, index) => ({
                ...component,
                key: index.toString(),
            }))}
        </StyledDiv>
    );
};

StarRating.propTypes = {
    rating: PropTypes.number.isRequired,
    size: PropTypes.string,
};

StarRating.defaultProps = {
    size: null,
};

export default StarRating;
