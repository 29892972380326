import React from 'react';
import PropTypes from 'prop-types';
import StarRating from '../StarRating';
import {
    Divider, Grid, Segment, Header, Button, Image, Modal,
} from 'semantic-ui-react';



const Body = props => props.children;
const Heading = props => props.children;
const Details = props => props.children;
const Review = props => props.children;
const Additional = props => props.children;

Body.displayName = 'Body';
Heading.displayName = 'Heading';
Details.displayName = 'Details';
Review.displayName = 'Review';
Additional.displayName = 'Additional';


const Wrapper = (props) => {
    const { children, logo, link, rating, company} = props;
    const sections = {
        body: React.Children.toArray(children).filter(child => child.type.displayName === 'Body'),
        heading: React.Children.toArray(children).filter(child => child.type.displayName === 'Heading'),
        details: React.Children.toArray(children).filter(child => child.type.displayName === 'Details'),
        review: React.Children.toArray(children).filter(child => child.type.displayName === 'Review'),
        additional: React.Children.toArray(children).filter(child => child.type.displayName === 'Additional'),
    };
    const [open, setOpen] = React.useState(false)


    return (
        <Segment>
            <Grid stackable columns={2}>
                <Grid.Row>
                    <Grid.Column width={7} textAlign="center">
                        <Segment className="company">
                            <div className="company-logo">
                                <Image
                                    src={logo}
                                    as='a'
                                    href={link}
                                    target='_blank'
                                    rel="noopener noreferrer sponsored"
                                    alt={company}
                                />
                            </div>
                            <div className="ratings">
                                <div className="ratings--content">
                                    DebtConsolidation.com's Rating
                                </div>
                                <StarRating rating={rating} />
                            </div>
                            <Button
                                size="big"
                                href={link}
                                target='_blank'
                                rel="noopener noreferrer sponsored"
                            >
                                Start Now
                            </Button>
                            <Divider hidden className="divider__small" />
                            <Modal
                                centered={false}
                                onClose={() => setOpen(false)}
                                onOpen={() => setOpen(true)}
                                open={open}
                                className="modal--reviews"
                                trigger={
                                    <Button 
                                        basic
                                        size="large"
                                        color="grey"
                                    >
                                        Our Review
                                    </Button>
                                }
                            >
                                <Modal.Header>More about {company}</Modal.Header>
                                <Modal.Content>
                                    <Modal.Description>
                                        <Grid celled="internally" divided stackable>
                                            <Grid.Row>
                                                <Grid.Column width={6} textAlign="center">
                                                    <Image
                                                        src={logo}
                                                        as='a'
                                                        href={link}
                                                        target='_blank'
                                                        rel="noopener noreferrer sponsored"
                                                        alt={company}
                                                        fluid
                                                    />
                                                    <div className="ratings">
                                                        <div className="ratings--content">
                                                            DebtConsolidation.com's Rating
                                                        </div>
                                                        <StarRating rating={rating} />
                                                    </div>
                                                    <Button
                                                        size="big"
                                                        href={link}
                                                        target='_blank'
                                                        rel="noopener noreferrer sponsored"
                                                        color="green"
                                                    >
                                                        Start Now
                                                    </Button>
                                                    {sections.additional}
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <Header size="large">
                                                        {sections.heading}
                                                    </Header>
                                                    {sections.review}                                                    
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Modal.Description>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick={() => setOpen(false)}>
                                        Close
                                    </Button>
                                </Modal.Actions>
                            </Modal>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={9}>
                        <Header size="large">
                            {sections.heading}
                        </Header>
                        {sections.body}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />
            {sections.details}
        </Segment>
    );
};

Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
    logo: PropTypes.node.isRequired,
    link: PropTypes.node.isRequired,
    rating: PropTypes.node.isRequired,
    company: PropTypes.node.isRequired,
};

export {
    Wrapper, Body, Heading, Details, Review, Additional,
};
